


import React,{useEffect,useState} from 'react';
import axios from 'axios'
import {
  Avatar,
  Chip,
    Dialog,
    DialogActions, 
     DialogContent,
     IconButton,
     Typography
  } from "@material-ui/core/";
import TaleAction from './TaleAction';
import config from '../config';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';



export default function SimpleDialog(props) {
    const { onClose, selectedValue, open } = props;

    //console.log("selectedValue ",selectedValue)
    const styles = (theme) => ({
      root: {
        margin: 0,
        padding: theme.spacing(2),
      },
      closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
      },
    });
    const DialogTitle = withStyles(styles)((props) => {
      const { children, classes, onClose, ...other } = props;
      return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
          <Typography variant="h6">{children}</Typography>
          {onClose ? (
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
             <span className="material-icons">close</span>
            </IconButton>
          ) : null}
        </MuiDialogTitle>
      );
    });

    const handleClose = () => {
      onClose(selectedValue);
    };
  
    const handleListItemClick = (value) => {
      onClose(value);
    };
  
     const updateViewCountApi = async(tale)=>{
  
      const url = `${config.apiUrl}/v1/public/tales/updateView`;
      return axios.put(url,{taleId:tale.taleId , viewCount : tale.viewCount})
      .then(response => response.data);
    }
  
    useEffect(async () => {
      if(selectedValue.taleId !== undefined && open)
     {
        //console.log("Masal görüntülendi 1",selectedValue.taleName , selectedValue.taleId)
       await updateViewCountApi(selectedValue)
      }
    }, [selectedValue,open])
  
    return (
      <Dialog onClose={handleClose}  aria-labelledby="simple-dialog-title" open={open} fullWidth={true} maxWidth={"lg"}>
      
          <DialogTitle onClose={handleClose}>
          {selectedValue.taleName}
  
          {/* <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <span className="material-icons">close</span>
              </IconButton> */}
  
          </DialogTitle>
  
       
     <DialogContent dividers>
     <div className="taleText" dangerouslySetInnerHTML={{ __html: selectedValue.taleTextEnd}} />
  
     </DialogContent>
     <DialogActions className='row justify-content-between'>
     {/* <div className='col-md-4 col-xs-12'>
      <span className='mr-2'>Derleyen : </span>
     <Chip  size="small"
          avatar={<Avatar alt={selectedValue.compilerNameSurname} />}
          label={selectedValue.compilerNameSurname}
          variant="outlined"
          className="mr-2"
          
        />
     </div> */}
 
              <div className='col-md-12 col-xs-12'>
              <TaleAction  tale={selectedValue}/>

              </div>
  
     </DialogActions>
  
      </Dialog>
    );
  }